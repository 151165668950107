<template>
  <div class='contact block galleryBlock'>
  <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap" rel="stylesheet">

  <validationObserver
    ref="observer"
    v-slot="{ invalid }"
  >
    <div class='contactTitle'>
    <h2 class='text-center'>{{ $t('contact.title')}}</h2> 
    <h5 class='text-center'>{{ $t('contact.second-title')}}</h5>
    </div>
    
    <v-row
    align="center"
    justify="center"
    class = 'flex-column-reverse flex-md-row'
    >
    <v-col 
      :cols = '12'
      :md = '6'>
    <form class='contact-form' @submit.prevent="sendEmail" id="contact_form_gouttedor">
      <v-container class='card-border'>
          <input type="hidden" name="contact_number">
          <v-row align='center' justify='center'>
            <v-col :cols='12' :sm='6'>
            <ValidationProvider
              v-slot="{ errors }"
              v-bind:name="$t('contact.name')"
              rules="required|max:64"
            >
              <v-text-field
                v-model="name"
                :counter="64"
                :error-messages="errors"
                v-bind:label= "$t('contact.name')"
                required
                name="user_name"
                id='from_name'                
              ></v-text-field>
            </ValidationProvider>
            </v-col>

            <v-col :cols='12' :sm='6'>
              <ValidationProvider
                v-slot="{ errors }"
                v-bind:name="$t('contact.surname')"
                rules="required|max:64"
              >
                <v-text-field
                  v-model="surname"
                  :counter="64"
                  :error-messages="errors"
                  v-bind:label= "$t('contact.surname')"
                  required
                  name="user_surname"
                  id='user_surname'                  
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols='12' sm='6'>
              <ValidationProvider
                v-slot="{ errors }"
                v-bind:name="$t('contact.phone')"
                rules="required|max:15"
              >
                <v-text-field
                  v-model="phoneNumber"
                  :counter="14"
                  :error-messages="errors"
                  v-bind:label= "$t('contact.phone')"
                  required
                  name="user_phone"
                  id='user_phone'                  
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols='12' sm='6'>
              <ValidationProvider
                v-slot="{ errors }"
                name="e-mail"
                rules="required|email"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  v-bind:label= "$t('contact.mail')"
                  required
                  name="user_email"
                  id='user_email'                  
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            
            <v-col cols='12' sm='6'>
              <ValidationProvider
                v-slot="{ errors }"
                v-bind:name="$t('contact.message')"
                rules="required"
              >
                <v-textarea
                  v-model='message_form'
                  :error-messages="errors"
                  name="message_form"
                  v-bind:label= "$t('contact.message')"
                  color="orange orange-darken-4"
                  id= 'message'
                  required
                ></v-textarea>
              </ValidationProvider>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols='12' sm='6' align = 'center'>
              <v-btn
                class="mr-4"
                type="submit"
                :disabled="invalid || disabled"
                color='rgb(236, 219, 68)'
                x-large
                id='button'
                value='submit'
              >
                {{ $t('contact.button')}}
              </v-btn>
            </v-col>
        </v-row>
      </v-container>
    </form>
    </v-col>

    <v-col
      :cols = '12'
      :md = '6'
      align = 'center'
    >
      <div class='text-center'>
        <b>{{ $t('contact.contact-info')}} Traiteur La Goutte d'Or </b>
        <div class='underline-gouttedor'></div>
        <br>
        Edgar Khoury 
        <br> info@lagouttedor.be	
        <br> +32 475 961835
      </div>
    </v-col>
    </v-row>
  </validationObserver>

  <ContactSuccess 
  :snackbar='snackbar' 
  @close-snackbar= 'toggleSnackbar'
  v-bind:msg="$t('contact.contact-success')"
  />
  
  </div>
</template>

<script>
  import { required, digits, email, max } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import ContactSuccess from './ContactSuccess.vue'
  import i18n from '../i18n'


  setInteractionMode('eager')

  import emailjs from "emailjs-com";

  extend('digits', {
    ...digits,
    message: `${i18n.t('error.digits')}`,
  })

  extend('required', {
    ...required,
    message: `${i18n.t('error.required')}`,
  })

  extend('max', {
    ...max,
    message: `${i18n.t('error.max')}`,
  })

  extend('email', {
    ...email,
    message: `${i18n.t('error.email')}`,
  })

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      ContactSuccess
    },
    data: () => ({
      name: '',
      surname:'',
      phoneNumber: '',
      email: '',
      message_form: '',
      message_error:[
        {
          test: 'contact.title'
        }
      ],
      snackbar:false,
      disabled: false
    }),

    methods: {
      submit () {
        this.$refs.observer.validate()
      },
      toggleSnackbar() {
        this.snackbar = !this.snackbar;
      },
      clear () {
      this.name = ''
      this.surname = ''
      this.phoneNumber = ''
      this.email = ''
      this.message_form = ''
      this.$refs.observer.reset()
      },
      sendEmail(e) {
        if(this.disabled) return;
        this.disabled = true;

        emailjs.sendForm('contact_service_lagoutte', 'contact_form_gouttedor', e.target, 'user_LMZVqBRF59yBQzujU8r2t')
          .then((result) => {
              console.log('SUCCESS!', result.status, result.text);
              this.toggleSnackbar();
              this.disabled = false;
              this.clear();
              // router.push({path: '/'})
          }, (error) => {
              this.disabled = false;
              console.log('FAILED...', error);
          });
      }
    }
  }
</script>

<style scoped>

.block {
  padding: 60px 0;
  border-bottom: 1px solid darken(white, 10%);
  margin: 0px 50px;
}

  .contactTitle {
    font-size: 30px;
    padding: 0 0 10px;
    margin: 0 0 40px;
    position: relative;
  }

  h5 {
    font-weight:normal;
  }

  .card-border {
    border: solid 1px rgb(236, 219, 68);
    border-radius: 15px;
    width: 95%;
  }

  .underline-gouttedor{
    height: 1px;
        background-color: #E6E6E6;
        margin: 0 auto;
        background-image: linear-gradient(to left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -o-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -moz-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -webkit-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -ms-linear-gradient(left , white 2%, #E6E6E6 50%, white 98%);
        background-image: -webkit-gradient( linear, left bottom, right bottom, color-stop(0.02, white), color-stop(0.5, rgb(236, 219, 68)), color-stop(0.98, white) );
  }

</style>