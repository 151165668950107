<template>
    <div class="text-center ma-2">

    <v-snackbar
      v-model="snackbar"
      color="rgb(236, 219, 68)"      
      outlined
      top
    >
    <div class='content-snackbar'>
      {{ msg }}
    </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="rgb(236, 219, 68)"
          v-bind="attrs"
          @click="closeSnackbar()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'ContactSuccess',
    props: {
      msg: String,
      snackbar: Boolean
    },
    methods: {
      closeSnackbar() {
        this.$emit('close-snackbar');
      },
    }
  }
</script>

<style>
  .content-snackbar{
    color: black;
  }
</style>