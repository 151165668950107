var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact block galleryBlock"},[_c('link',{attrs:{"href":"https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap","rel":"stylesheet"}}),_c('validationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"contactTitle"},[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('contact.title')))]),_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('contact.second-title')))])]),_c('v-row',{staticClass:"flex-column-reverse flex-md-row",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":12,"md":6}},[_c('form',{staticClass:"contact-form",attrs:{"id":"contact_form_gouttedor"},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail($event)}}},[_c('v-container',{staticClass:"card-border"},[_c('input',{attrs:{"type":"hidden","name":"contact_number"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":12,"sm":6}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.name'),"rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":64,"error-messages":errors,"label":_vm.$t('contact.name'),"required":"","name":"user_name","id":"from_name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":12,"sm":6}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.surname'),"rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":64,"error-messages":errors,"label":_vm.$t('contact.surname'),"required":"","name":"user_surname","id":"user_surname"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.phone'),"rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":14,"error-messages":errors,"label":_vm.$t('contact.phone'),"required":"","name":"user_phone","id":"user_phone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('contact.mail'),"required":"","name":"user_email","id":"user_email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.message'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"name":"message_form","label":_vm.$t('contact.message'),"color":"orange orange-darken-4","id":"message","required":""},model:{value:(_vm.message_form),callback:function ($$v) {_vm.message_form=$$v},expression:"message_form"}})]}}],null,true)})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","align":"center"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid || _vm.disabled,"color":"rgb(236, 219, 68)","x-large":"","id":"button","value":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('contact.button'))+" ")])],1)],1)],1)],1)]),_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('div',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(_vm.$t('contact.contact-info'))+" Traiteur La Goutte d'Or ")]),_c('div',{staticClass:"underline-gouttedor"}),_c('br'),_vm._v(" Edgar Khoury "),_c('br'),_vm._v(" info@lagouttedor.be "),_c('br'),_vm._v(" +32 475 961835 ")])])],1)]}}])}),_c('ContactSuccess',{attrs:{"snackbar":_vm.snackbar,"msg":_vm.$t('contact.contact-success')},on:{"close-snackbar":_vm.toggleSnackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }